import React from "react"
const Partner = ({ src, title, description }) => {
  return (
    <div className="partner">
      <img className="img" src={src} alt="" />
      <h2 className="title">{title}</h2>
      <p className="description">{description}</p>
    </div>
  )
}
export default Partner
