import React, { useEffect } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./components/body/home";
import Feature from "./components/body/feature";
import AboutUs from "./components/body/about_us";
import Partnership from "./components/body/partnership";
import NotFound from "./components/body/notfound";
import { useTranslation } from "react-i18next";
import FloatBubble from "./components/common/float_bubble";

//import "./munative.css";
//import "./webflow.css";
//import "./normalize.css";

const App = () => {
  const [t] = useTranslation("common");
  const [, setBubbleShow] = React.useState(false);

  useEffect(() => {
    const trackingId = "G-PCZJXEWEZ8";
    ReactGA.initialize(trackingId);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setBubbleShow(true);
    }, 2000);
  }, []);

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "Page View",
  });

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/">
            <Route exact path="/" index element={<Home />} />
            <Route exact path="/features" element={<Feature />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/partnership" element={<Partnership />} />
            <Route exact path="/not-found" element={<NotFound />} />
            <Route element={<Navigate to="/not-found" />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
      <FloatBubble
        bubbleShow={false}
        setBubbleShow={setBubbleShow}
        title={t("body.internProgram.title")}
        description={t("body.internProgram.description")}
        link="https://forms.gle/nadNwhwiXGERBbCBA"
        linkText={t("body.internProgram.button")}
      />
    </>
  );
};

export default App;
