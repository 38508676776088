import React from "react";
const Developer = ({ head_shot, name, role, description }) => {
  return (
    <div className="developer">
      <img src={head_shot} alt="head shot" />
      <div className="text-block">
        <div className="name">{name}</div>
        <div className="role">{role}</div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default Developer;
