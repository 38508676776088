import React from "react";
const Faith = ({ title, description }) => {
  return (
    <div className="faith">
      <h2 className="title">{title}</h2>
      <p className="description">{description}</p>
    </div>
  );
};
export default Faith;
