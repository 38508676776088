import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "../common/banner";

import tesmun from "../../img/partnership/tesmun.png";

import BottomAction from "../common/bottom_action";
import ScrollAnimation from "react-animate-on-scroll";
import Partner from "./../common/partner";

const Partnership = () => {
  const { t } = useTranslation("common");

  return (
    <React.Fragment>
      <ScrollAnimation animateIn="slideInLeft" animateOnce={true} duration={1}>
        <Banner
          title={t("body.partnership.partnership")}
          description={t("body.partnership.sentences.partnership")}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={1}>
        <div className="partnership-block">
          {/* <h1 className="title">感謝...</h1> */}

          <div className="board">
            <div className="grid">
              <Partner
                src={tesmun}
                title="TESMUN"
                description="Our club holds two core beliefs: one, that the world is only becoming more interconnected and global issues are becoming more relevant to every individual; two, that MUN can enable future leaders, citizens, and parents to make more aware decisions through a global perspective. The Taipei European School Model United Nations club seeks not only to develop transferrable skills in students, involving consensus-building, critical thinking, and public speaking but also to prepare the next generation for their pivotal roles in building a brighter future."
              />
            </div>
          </div>
          <div className="decorate-ball"></div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={1}>
        <BottomAction
          title={t("commons.contactUs")}
          description={
            <>
              {t("body.aboutUs.sentences.contactUsOne")}
              <br></br>
              {t("body.aboutUs.sentences.contactUsTwo")}
            </>
          }
          button={t("commons.reserve")}
          href="https://forms.gle/rPKNKmdnd6mMd2TJ8"
        />
      </ScrollAnimation>
    </React.Fragment>
  );
};

export default Partnership;
