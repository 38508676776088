import React from "react";
import { ReactComponent as CloseIcon } from "../../img/close.svg";
// import { useTranslation } from "react-i18next";

const FloatBubble = ({
  setBubbleShow,
  bubbleShow,
  title,
  description,
  link,
  linkText,
}) => {
  // const [t] = useTranslation('common')
  return (
    <div
      className="float-bubble"
      style={{
        opacity: bubbleShow ? 1 : 0,
        pointerEvents: bubbleShow ? "auto" : "none",
      }}
    >
      <span className="close-button" onClick={() => setBubbleShow(false)}>
        <CloseIcon />
      </span>
      <h1>{title}</h1>
      <p>{description}</p>
      <a
        className="button"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
    </div>
  );
};
export default FloatBubble;
