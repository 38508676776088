import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "../common/banner";

const NotFound = () => {
  const { t } = useTranslation('common');

  return (
    <React.Fragment>
      <Banner
        title={t("commons.notFound")}
        description={t("commons.notFoundDescription")}
      />
      <div style={{height:"120px"}}/>
    </React.Fragment>
  );
};

export default NotFound;
