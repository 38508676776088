import React from "react"

import Linkedin from "../../img/linkedin.svg"
import Instagram from "../../img/instagram.svg"
import Facebook from "../../img/facebook.svg"
import Github from "../../img/github.svg"
import { useLocation } from "react-router-dom"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const location = useLocation()
  const display = location.pathname.indexOf("not-found") > -1 ? 1 : 0
  return (
    <div className={display === 1 ? "footer not-found" : "footer"}>
      <div className="copyright-text">
        Copyright © {currentYear} Munative
        <br />
        All Rights Reserved
      </div>

      <div className="community-list">
        <a
          href="https://www.linkedin.com/company/munative"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Linkedin} alt="" />
        </a>
        <a href="https://www.instagram.com/munativeteam/" target="_blank" rel="noopener noreferrer">
          <img src={Instagram} alt="" />
        </a>
        <a href="https://www.facebook.com/munative" target="_blank" rel="noopener noreferrer">
          <img src={Facebook} alt="" />
        </a>
        <a href="https://github.com/munative/" target="_blank" rel="noopener noreferrer">
          <img src={Github} alt="" />
        </a>
      </div>
    </div>
  )
}
export default Footer
