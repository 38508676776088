import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

const Trait = ({ id, img, title, description, button, reverse }) => {
  const { t } = useTranslation("common");
  return (
    <div id={id} className={reverse ? "trait-reverse" : "trait"}>
      <div className="text-block">
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
        {button ? (
          <Link className="button" to={button}>
            {t('commons.seeMore')}
          </Link>
        ) : (
            ""
          )}
      </div>

      <div className="color-block">
        <img className="image" src={img} alt="" />
      </div>

      <img className="imageFixed" src={img} alt="" />

    </div>
  );
};

export default Trait;
