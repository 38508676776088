import React from "react"
import { useTranslation } from "react-i18next"

import img_laptop from "../../img/laptop.svg"
import img_dashboard from "../../img/dashboard.svg"
import img_note from "../../img/note.svg"

import Faith from "../common/faith"
import Trait from "../common/trait"
import BottomAction from "./../common/bottom_action"
import "../../style.css"

import ScrollAnimation from "react-animate-on-scroll"

const Home = () => {
  const { t } = useTranslation("common")

  return (
    <React.Fragment>
      <ScrollAnimation animateIn="slideInLeft" animateOnce={true} duration={1}>
        <div className="theme-block">
          <div className="decorate-ball" />
          <p className="paragraph">{t("body.home.haveThought")}</p>
          <h1 className="title">
            {t("body.home.paperless")}
            <br />
            {t("body.home.mun")}
          </h1>
          <a
            href="https://forms.gle/rPKNKmdnd6mMd2TJ8"
            target="_blank"
            rel="noopener noreferrer"
            className="button-reservation"
          >
            {t("commons.reserve")}
          </a>
          <img className="image" src={img_laptop} alt="img laptop" />
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={0.7}>
        <div className="sign-block">
          <h2 className="title">{t("body.home.sentences.unprecedented")}</h2>
          <p className="subtitle">{t("body.home.sentences.easyFunction")}</p>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={0.7}>
        <div className="faith-block">
          <h2 className="title">{t("body.home.mission")}</h2>
          <div className="decorate-ball" />
          <div className="board">
            <div className="grid">
              <Faith title={t("body.home.easy")} description={t("body.home.sentences.easy")} />
              <Faith
                title={t("body.home.accurate")}
                description={t("body.home.sentences.accurate")}
              />
              <Faith
                title={t("body.home.ecoFriendly")}
                description={t("body.home.sentences.ecoFriendly")}
              />
              <Faith
                title={t("body.home.intuitive")}
                description={t("body.home.sentences.intuitive")}
              />
            </div>
          </div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} duration={0.7}>
        <Trait
          img={img_dashboard}
          title={t("commons.dashboard")}
          description={t("body.home.sentences.dashboard")}
          button="/features#dashboard"
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={0.7}>
        <Trait
          img={img_note}
          title={t("commons.note")}
          description={t("body.home.sentences.note")}
          button="/features#note"
          reverse
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={0.7}>
        <BottomAction
          title={t("body.home.reserveQuick")}
          description={t("body.home.sentences.reserverQuick")}
          button={t("commons.reserve")}
          href="https://forms.gle/rPKNKmdnd6mMd2TJ8"
        />
      </ScrollAnimation>
    </React.Fragment>
  )
}

export default Home
