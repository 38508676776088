import React from "react";
const Banner = ({title,description}) => {
  return (
    <div className="banner">
      <div className="decorate-ball" />
      <h1 className="title">
        {title}
      </h1>
      <p className="paragraph">{description}</p>
    </div>
  );
};

export default Banner;
