import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import icon from '../../logo.svg'
import { ReactComponent as MenuButton } from '../../img/menu-button.svg'
import { ReactComponent as Global } from '../../img/global.svg'

import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const Header = () => {
  const [mobileNavFirstExpanded, setMobileNavFirstExpanded] = useState(false)
  const [mobileNav, setMobileNav] = useState(false)
  const location = useLocation()

  const [t, i18n] = useTranslation('common')

  return (
    <div className="header">
      <Link className="icon" to="/">
        <img href="/" src={icon} alt="munative icon" />
      </Link>

      <div className="navbar" onClick={() => setMobileNav(false)}>
        <Link className={location.pathname === '/' ? 'active' : ''} to="/">
          {t('commons.home')}
        </Link>

        <Link className={location.pathname === '/features' ? 'active' : ''} to="/features">
          {t('body.feature.features')}
        </Link>

        <div className="about-us">
          <div
            className={
              'title ' +
              (location.pathname === '/about-us' || location.pathname === '/partnership' ? 'active' : '')
            }
          >
            {t('body.aboutUs.aboutUs')}
          </div>
          <div className="submenu">
            <Link
              className={location.pathname === '/about-us' ? 'active' : ''}
              to="/about-us"
            >
              {t('body.aboutUs.ourTeam')}
            </Link>
            <Link
              className={location.pathname === '/partnership' ? 'active' : ''}
              to="/partnership"
            >
              {t('body.partnership.partnership')}
            </Link>
          </div>
        </div>

        {/* <a
          href='https://docs.google.com/forms/d/e/1FAIpQLSd0IBc30-5-5nkZOyHqaNnl4I6Wtbc_7rUslgqnklAIkt4oLQ/viewform'
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('body.internProgram')}
        </a> */}

        <Dropdown className="dropdown">
          <Dropdown.Toggle variant="">
            <Global fill="#1c2f3f" /> {t('commons.language')}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={() => i18n.changeLanguage('en')}>
              English
            </Dropdown.Item>
            <Dropdown.Item onClick={() => i18n.changeLanguage('zh')}>
              繁體中文
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="gotoAppButton">
          <a href="https://lite.munative.com/">{t('commons.app')}</a>
        </div>
      </div>

      <span
        className="navbar-m-button"
        onClick={() => {
          setMobileNavFirstExpanded(true)
          setMobileNav(!mobileNav)
        }}
      >
        <MenuButton fill="#1989FF" />
      </span>
      {mobileNavFirstExpanded ? (
        <div className={mobileNav ? 'navbar-m expand' : 'navbar-m'}>
          <div className="navbar-list">
            <Link onClick={() => setMobileNav(false)} to="/">
              {t('commons.home')}
            </Link>
            <Link onClick={() => setMobileNav(false)} to="/features">
              {t('body.feature.features')}
            </Link>
            <Link onClick={() => setMobileNav(false)} to="/about-us">
              {t('body.aboutUs.aboutUs')}
            </Link>

            <a href="https://lite.munative.com/">{t('commons.app')}</a>

            <Link onClick={() => setMobileNav(false)} to="/partnership">
              {t('body.partnership.partnership')}
            </Link>

            {/* <a
              href='https://docs.google.com/forms/d/e/1FAIpQLSd0IBc30-5-5nkZOyHqaNnl4I6Wtbc_7rUslgqnklAIkt4oLQ/viewform'
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('body.internProgram')}
            </a> */}

            <Dropdown shown="true" className="dropdown">
              <Dropdown.Toggle variant="">
                <Global fill="#1c2f3f" /> {t('commons.language')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    i18n.changeLanguage('en')
                    setMobileNav(false)
                  }}
                >
                  English
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    i18n.changeLanguage('zh')
                    setMobileNav(false)
                  }}
                >
                  繁體中文
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="touchFold" onClick={() => setMobileNav(false)}></div>
        </div>
      ) : null}
    </div>
  )
}

export default Header
