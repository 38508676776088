import React from "react"
import { useTranslation } from "react-i18next"
import Banner from "../common/banner"

import Alwin_Yen from "../../img/head_shot/Alwin_Yen.png"
import Eric_Hsu from "../../img/head_shot/Eric_Hsu.png"
import Elsa_Hsieh from "../../img/head_shot/Elsa Hsieh.png"
import Audrey_Leu from "../../img/head_shot/Audrey_Leu.png"
import Jasmine_Wu from "../../img/head_shot/Jasmine_Wu.png"
import Elaine_Chou from "../../img/head_shot/Elaine_Chou.png"
import Hentci_Ke from "../../img/head_shot/Hentci_Ke.jpg"
import Kenn_Huang from "../../img/head_shot/Kenn_Huang.jpeg"
import Leaf_Yeh from "../../img/head_shot/Leaf_Yeh.jpg"
import Lyon_Lu from "../../img/head_shot/Lyon_Lu.jpg"
import Serena_Cho from "../../img/head_shot/Serena_Cho.png"

import BottomAction from "../common/bottom_action"
import Developer from "../common/developer"
import ScrollAnimation from "react-animate-on-scroll"

const AboutUs = () => {
  const { t } = useTranslation("common")

  return (
    <React.Fragment>
      <ScrollAnimation animateIn="slideInLeft" animateOnce={true} duration={1}>
        <Banner
          title={t("body.aboutUs.aboutUs")}
          description={t("body.aboutUs.sentences.aboutUs")}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={1}>
        <div className="about-block">
          <h1 className="title">{t("body.aboutUs.whatIsMunative")}</h1>
          <div className="board">
            Digitizes your mun experience
            <br />
            <br />
            {t("body.aboutUs.sentences.whatIsMunative")}
          </div>
          <div className="decorate-ball"></div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} duration={1}>
        <div className="developer-block">
          <h1 className="title">{t("body.aboutUs.developers")}</h1>
          <div className="board">
            <div className="grid">
              <Developer
                head_shot={Alwin_Yen}
                name="Alwin Yen |  顏才翔"
                role={t("body.aboutUs.developerTitles.CoFounder")}
                description={t("body.aboutUs.developerIntros.Alwin")}
              />
              <Developer
                head_shot={Kenn_Huang}
                name="Kenn Huang | 黃薰磊"
                role={t("body.aboutUs.developerTitles.CoFounder")}
                description={t("body.aboutUs.developerIntros.Kenn")}
              />
              <Developer
                head_shot={Eric_Hsu}
                name="Eric Hsu | 徐廷宇"
                role={t("body.aboutUs.developerTitles.BusinessDirector")}
                description={t("body.aboutUs.developerIntros.Eric")}
              />
              <Developer
                head_shot={Audrey_Leu}
                name="Audrey Leu | 呂宥樺"
                role={t("body.aboutUs.developerTitles.BusinessDeveloper")}
                description={t("body.aboutUs.developerIntros.Audrey")}
              />
              <Developer
                head_shot={Elsa_Hsieh}
                name="Elsa Hsieh | 謝卉芸"
                role={t("body.aboutUs.developerTitles.BusinessDeveloper")}
                description={t("body.aboutUs.developerIntros.Elsa")}
              />
              <Developer
                head_shot={Elaine_Chou}
                name="Elaine Chou | 周瑀汧"
                role={t("body.aboutUs.developerTitles.BusinessDeveloper")}
                description={t("body.aboutUs.developerIntros.Elaine")}
              />
              <Developer
                head_shot={Hentci_Ke}
                name="Hentci Ke | 柯柏旭"
                role={t("body.aboutUs.developerTitles.BackEndDeveloper")}
                description={t("body.aboutUs.developerIntros.Hentci")}
              />
              <Developer
                head_shot={Jasmine_Wu}
                name="Jasmine Wu | 吳鈺茜"
                role={t("body.aboutUs.developerTitles.BusinessDeveloper")}
                description={t("body.aboutUs.developerIntros.Jasmine")}
              />
              <Developer
                head_shot={Leaf_Yeh}
                name="Leaf Yeh | 葉峻誠"
                role={t("body.aboutUs.developerTitles.BackEndDeveloper")}
                description={t("body.aboutUs.developerIntros.Leaf")}
              />
              <Developer
                head_shot={Lyon_Lu}
                name="Lyon Lu | 呂耀承"
                role={t("body.aboutUs.developerTitles.FrontEndDeveloper")}
                description={t("body.aboutUs.developerIntros.Lyon")}
              />
              <Developer
                head_shot={Serena_Cho}
                name="Serena Cho | 卓懿萱"
                role={t("body.aboutUs.developerTitles.BusinessDeveloper")}
                description={t("body.aboutUs.developerIntros.Serena")}
              />
            </div>
          </div>

          <div className="decorate-ball"></div>
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={1}>
        <BottomAction
          title={t("commons.contactUs")}
          description={
            <>
              {t("body.aboutUs.sentences.contactUsOne")}
              <br></br>
              {t("body.aboutUs.sentences.contactUsTwo")}
            </>
          }
          button={t("commons.reserve")}
          href="https://forms.gle/rPKNKmdnd6mMd2TJ8"
        />
      </ScrollAnimation>
    </React.Fragment>
  )
}

export default AboutUs
