import React from "react"
import { useTranslation } from "react-i18next"
import Banner from "../common/banner"
import Trait from "../common/trait"
import BottomAction from "../common/bottom_action"
import Dashboard from "../../img/dashboard.svg"
import Note from "../../img/note.svg"
import Amendment from "../../img/amendment.svg"
import Reso from "../../img/reso.svg"
import ScrollAnimation from "react-animate-on-scroll"

const Feature = () => {
  const { t } = useTranslation("common")

  return (
    <React.Fragment>
      <ScrollAnimation animateIn="slideInLeft" animateOnce={true} duration={1}>
        <Banner
          title={t("body.feature.features")}
          description={t("body.feature.sentences.features")}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={1}>
        <Trait
          id="note"
          img={Note}
          title={t("commons.note")}
          description={t("body.feature.sentences.note")}
          reverse
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} duration={1}>
        <Trait
          id="dashboard"
          img={Dashboard}
          title={t("commons.dashboard")}
          description={t("body.feature.sentences.dashboard")}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInRight" animateOnce={true} duration={1}>
        <Trait
          id="amendment"
          img={Amendment}
          title={t("body.feature.amendment")}
          description={t("body.feature.sentences.amendment")}
          reverse
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} duration={1}>
        <Trait
          id="resolution"
          img={Reso}
          title={t("body.feature.resolution")}
          description={t("body.feature.sentences.resolution")}
        />
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" animateOnce={true} duration={1}>
        <BottomAction
          title={t("body.feature.moreFeatures")}
          description={t("body.feature.sentences.moreFeatures")}
          button={t("commons.contactUs")}
          href="https://forms.gle/rPKNKmdnd6mMd2TJ8"
        />
      </ScrollAnimation>
    </React.Fragment>
  )
}

export default Feature
