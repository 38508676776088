import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_zh from "./translations/zh_TW/common.json";
import common_en from "./translations/en_US/common.json";
import "bootstrap/dist/css/bootstrap.css";
import "./style.css";

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        zh: {
            common: common_zh
        },
        en: {
            common: common_en
        }
    }
})
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
