import React from "react"

const BottomAction = ({ title, description, button, href }) => {
  return (
    <div className="bottom-action">
      <h1 className="title">{title}</h1>

      <div className="description">
        <p className="inner">{description}</p>
      </div>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="button"
        href={href}
      >
        {button}
      </a>
    </div>
  )
}

export default BottomAction
